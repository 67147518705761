
html.ios {
  overflow-x: initial;
  -webkit-overflow-scrolling: touch;
}

html.mobile:not(.blank) {
	height: 120%; // fix mobile body height always not fill up screen height issue
}

.mobile {

	h1.doulaeasy-slogan.text {	
		margin-top: 0!important;
		margin-bottom: 1em!important;
	}

	.main.page.board {
		box-shadow: 1px 5px 3px 4px rgba(100, 100, 100, 0.4);
		background-color: @doulaeasy_bg_red_deeper;
		background-size: 100% 100%;
		padding-top: 0.7em;
		padding-bottom: 5px;
		border: none;
		margin-top: 0.7em;
		margin-bottom: 1em;
		border-radius: 0.4em;
		// z-index: initial;
	}	

	body.blank {
		min-height: 0px;
		background-color: #ffe8e4;
	}
	main {
		background: white;
	}
	.doulaeasy-logo {
		width: auto;
		height: 65px;
	}

	.toggle-desktop-version {
		padding-bottom: 25px;
	}

	div.jump-to-card-section.ui.grid {
		margin-top: 1em;
	}
	.home.content {
		// .column {
		// 	padding-left: 6%;
		// 	padding-right: 6%;
		// }
		.ui.list .item>.content {
			width: 16em;
		}
		.ad.item {
			width: inherit;
		}

		.h1-ad {
			// height: 250px;
		}
		.articles.listing .ui.items .item {
		    margin: 0.3em 0em;
		}
	}
	#facebook_like_column {
		padding: 0.7em!important;
		// height: 60px;
		// padding-right: 0.5em!important;
	}
	#google_plusone_column {
		padding: 0.7em!important;
		// height: 1.2em;
		// height: 60px;
		// padding-left: 0.5em!important;
  		// padding-top: 0.05em!important;
	}

	.ui.modal {
		width: 90%;
		margin-left: -45%;
	}

	.ui.modal > .close {
		position: fixed;
		top: -2.2em !important;
		right: 0.35em;
		color: #FFF;
		z-index: 100;
		opacity: .8;
		font-size: 1.25rem;
	}

	#prompts_modal.ui.modal {

		.ui.tab.segment {
			height:32em!important;
			overflow:auto;
		}
	}

	.top.banner.row {
		padding-top: 20px;
	}

	.top.banner .text {
		text-align: center;
	}

	.top.banner .title {
		margin-top: -0.1em;
		margin-left: 0.5em;
	}


	#footer {
	    padding: 1em;
   	}

	#status_labels {
		// margin-left:-6px;
		// margin-right:-6px;
		.ui.label {
			margin-top: 5px;
			margin-bottom: 5px;
			font-size: 1em;
		}
		.ui.list.user-label {
			margin: 0 2em;
		}
		.ui.right.floated.header {
		  margin-top: 1em;
		}
	}

	#register_modal {
		#helper_register_button {
			img {
				margin-top: 1em;
			}
		}
	}

	.helper-profile-actions.ui.labeled.icon.buttons .button {
		position: relative;
	    padding-left: 3.6em!important;
	    padding-right: 0.6em!important;
	}


	.ui.main.inner.page.segment {
		padding-left: 6px;
		padding-right: 6px;
	}

	.outer.main.page.segment {
		padding-left: 8px;
		padding-right: 8px;
	}

	#helper_show_inner_div {
		width: 100%;
		margin-top: 10px;
	}

	div.sidebar-right-menu {
	    background-color: #fff;
	    padding: 1em 0 0.2em 0;
	    margin: 0 1em;
	    border-radius: 0.4em;
	}

	div.launch_menu_button.transparent {
    	background: transparent;
    }

	div.launch_menu_button {
		position: fixed;
		left: 0px;
	  	padding: 1em;

		top: 2em;
	    color: white;
	    left: 0px;
	    background: rgba(233, 127, 104, 0.52);
	    border-radius: 0 .4em .4em 0;
	    

		span.text {
			font-size: 1.3em;
		}
	}

	div.launch_menu_button.close-menu {
		position: relative;
		padding: 0 0 0 1em;
		top: initial;
	}

	div.main-menu.sidebar {
		overflow-x: hidden;
		background: rgba(223, 151, 151, 0.94);	
		// z-index: 1100;
		.ui.accordion {
			margin-left: auto;
			margin-right: auto;
			font-size: 1.1em;
		}
		.ui.large.menu .item .item {
  			font-size: 1em;
  		}
		.launch.attached.button {
		}
		#toggle_desktop_button {
			position: absolute;
			top: 1em;
			right: 1em;
			z-index: 1;
		}
	} 

	.helper-profile {
		.ui.basic.segment {
			margin-top:0;
			padding-top:0;
		}
		.ui.page.grid {
			margin-top: 20px;
		}
		.reference-map {
		}
		.region {
		}

		.ui.right.floated.header {
			margin-top: 10px;
		}

		.ui.page.grid > .column {
			padding: 0 0px;
		}
		#e1_ad_item {
			height: 250px;
		}
		#e2_ad_item {
			height: 250px;
		}
	}
	
	div.more-info-fields.ui.grid {

		.other-region.ui.dropdown {
			margin-top: 15px;
		}
		.hong-kong-district.ui.select {
			margin-top: 15px;
		}
		.provinces.ui.select {
			margin-top: 15px;
		}
		.source-others.ui.input {
			margin-top: 15px;
		}
	}

	#loading_iframe {
	  // left: 43%;
	  // top: 43%;
	  // position: absolute;
	}

	#matching_search_text h2 {
		margin-bottom:2px;
	}
	#select_role_buttons h2{
		margin-top:2px;
	}

	.right.header.button {
		margin: 0px;
		padding-top: 10px;
		padding-bottom: 13px;
		border-radius: 0px;
		text-transform: none;
	}
	.right.header.button .icon.dropdown {
		padding-right: 0px;
		width: 100px;
	}

	div.ui.list.user-menu {
		a.item {
			color: inherit;
			padding: 0.8em;
		}
	}

	.ui.compact.vertical.menu .item:last-child::before {
		display: none;
	}

	.ui.compact.vertical.menu > .item:last-child::before {
		display: block;
	}

	.self.introduction.text {
		word-wrap: break-word;
		max-width: 400px;
	}

	.ui.rotate.reveal.no-hover:hover>.visible.content {
    	-webkit-transform: none;
		-moz-transform: none;
		-ms-transform: none;
		transform: none; 
	}

	.ui.rotate.reveal.hover>.visible.content {
    	-webkit-transform: rotate(110deg);;
		-moz-transform: rotate(110deg);;
		-ms-transform: rotate(110deg);;
		transform: rotate(110deg);; 
	}

	.ui.label .helper_card_email_text a {
		color: -webkit-link;
		text-decoration: underline;
	}
	.ui.label .helper_card_phone_text a {
		color: -webkit-link;
		text-decoration: underline;
	}

	a.tel-phone {
		color: -webkit-link;
		text-decoration: underline;
	}

	.edit.page.content .row {
		margin-bottom: 2em;
	}
	
	// .ui.stackable.grid>.column, .ui.stackable.grid>.row>.column {
	// 	padding-top: 2px !important;
	// }

	// responsive video container, e.g. for YouTube
	.video-container {
	    position: relative;
	    padding-bottom: 56.25%;
	    padding-top: 30px; height: 0; overflow: hidden;
	}
	.video-container iframe,
	.video-container object,
	.video-container embed {
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	}

	.doulaeasy.phone.label {
		padding-top: 0.3rem;
		padding-bottom: 0.28rem;
	}
	.doulaeasy.whatsapp.label {
		padding-top: 0.3rem;
		padding-bottom: 0.28rem;
	}

	// .doulaeasy.whatsapp.label {
	// 	// padding-top: 0rem;
	// 	padding-bottom: 0.2rem;
	// 	padding-left: 0.4rem;
	// 	padding-right: 0.4rem;
	// 	height: 1.55rem;
	// 	a {
	// 		top: 0.2rem;
	// 	}
	// 	img {
	// 		top: 0.25rem;
	// 		height: 0.9rem;
	// 		width: 0.9rem;
	// 	}
	// }

	.blog.index {
		h2.page.title {
			margin-top: 0.6em;
		}
		div.gallery-view {
			.post-item {
				min-height: 200px;
			}
		}
		div.list-view {
			margin-top: 15px;
			div.content {
				line-height: 1.5em;
				width: 250px;
			}
		}
	}

	.blog.page {
		.main-content {
			.content.page {
				padding: 0.3em;
			}
		}
	}

	.content.page {
		nav {
			padding-left: 1em;
			padding-right: 1em;
		}
	}

	// for blog post index item: padding is lost in mobile
	.ui.stackable.items>.item, .ui.stackable.items>.row>.item {
		display: block!important;
		height: auto!important;
		width: 100%!important;
		padding: 0.4em!important; // affecting helper cards' distance between each other
	}

	.about.author.segment .row.content {
		margin-top: 1em;
	}

	#search_result_segment {
		padding-left: 0px;
		padding-right: 0px;

		#search_result_content {
			padding-top: 0.5em;
		}
	}

	#helper_schedule_calendar {
		width: 100%!important;
		>.ui-datepicker {
			width: 100%!important;
		}
	}
	.single.post {
		.post.content {
			section.main.content {
				padding-left: 5px;
				padding-right: 5px;
			}
			.social.elements {
				height: 53px;
				margin-left: 4px;
				.fb-like {
					height: 25px;
				}
				.facebook.like {
					margin-top:5px;
				}
				.google.share {
					margin-top:10px;
				}
			}
		}
	}

	.ui.items>.item>.image+.content {
		padding-left: 0.5em;
	}

	.social.share.column {
		margin-top: 6px!important;
		margin-bottom: 10px!important;
	}


	.ui.segment.event.details {
		padding-left: 1em;
		padding-right: 1em;
		padding-bottom: 1.5em;
	}

	.side-panel {
		*[id^='oio-banner-'] { // id that start with oio-banner-
			max-width: inherit!important;
		}
		// font-size: 0.7rem;
	}

	.magnific-image {
		img {
			max-width: 300px;
			height: auto;
		}
	}

	#boxes {
		margin-left: 0em;
		margin-right: 0em;
		margin-top: 1em;

		.ad.item {
	  		width: initial;
  			margin: 0.5em;
			div {
				width: 100%!important;	
				padding-top: 0.3em!important;
				padding-bottom: 0.3em!important;
			}
		}

		.helper-card {
				
			.ui.cards {
				.card {
					min-height: initial!important;
					.rounded-avatar img {
						width: 7.5rem!important;
						height: 8.5rem!important;
					}
				}
			}

			img.front.badge {
				top: 8px;
			}

			>.content {
				padding: 0;
			}

			.ui.cards {
				margin: auto;
			}
		}
		
	}

	.hiring-post {
		div.ui.list.hiring-label {
			div.ui.label {
				margin: 0.5em;
			}
		}

		.ui.cards {
			.hiring-card.card {
				margin-top: 1.5em!important;
			}
		}
	}

	.search.bar {
		.hiring-period {
			.small.input {
				font-size: 1rem;
			}
		}
		.ui.outer.segment {
			// margin-top: 1.5em;
			padding-top:1.8em;
			padding-bottom: 2.3em;
		}
		.more-search-filters {
			margin-top: 0;
			margin: 0px!important;
		}
		.title-column {
			// padding-left: 0;
  			padding-right: 0.2em;
			h2 {
				font-size: 1.2em!important;
				padding-left: 0.2em;
			}
		}
		.select-role-column {
			padding-left: 0;
  			padding-right: 0;
  		}
		#more_search_filters_button {
			padding: 0.6em;
			margin-left: 0.6em;
			margin-right: 0.6em;
			width: 95%;
		}
	}

	.search.result .ui.three.items > .item {
	  margin-left: -4px;
	}

	.social-elements {
		.fb-like, .fb-like iframe[style] {width:  320px !important;display:block;z-index:2000;position:relative}
		.fb-like span, .fb-like iframe  span[style] {width: 320px !important;display:block;z-index:2000;position:relative}	
	}

	div.more-search-filters {
		padding-top: 0em!important;
	}

	.ui.modal.popup-theme-1 {
		width: 90% !important;
	  	margin-left: -45% !important;
	}

	// ********** mobile common css: END ***********

	@media only screen and (min-width: 350px) {
		#helper_schedule_calendar .ui-datepicker {
			// width: 104% !important;
			font-size: 0.7rem!important;
			// margin-left: -10px;
		}
	}

	@media only screen and (max-width: 349px) {
		#helper_schedule_calendar .ui-datepicker {
			// width: 104% !important;
			font-size: 3px!important;
			padding: .1em 0;
			// margin-left: -10px;
			// table {
			// 	font-size: 3px!important;
			// }
		}
	}

	@media only screen and (max-width: 330px) { // for iphone4 and iphone5

		#facebook_like_column {
			height: 18px;
			padding: 0px!important;
		}
		#google_plusone_column {
			height: 18px;
			padding: 0px!important;
		}
		#helper_card_email_text {
			bottom: -5px !important;
		}
		.outer.main.page.segment {
			background-size: 250%;
		}
		
		#parent_register_button img {
			width: 90%;
    		height: 90%;
		}
		#helper_register_button img {
			width: 90%;
    		height: 90%;
		}

		#boxes {
			.helper-card.outer.card {
				max-height: 210px!important;
				min-height: 180px!important;
			}
			.helper-card {
				.content.item {
					width: 214px;
					min-height: 170px;
				}
				.upper {
					height: 110px;
				}
			}
		}
		
		.ui.small.segment {
			padding-bottom: 0.3em;
		}
		
		.self.introduction.text {
			max-width: 230px !important;
		}
		.ui.page.grid {
			min-width: 230px;
		}
		p.sub.text.large  {
			font-size: 1.3rem;
		}

		// fix facebook comments width to 0px problem
		.fb-comments, .fb-comments span, .fb-comments iframe { 
			width: 220px !important; 
		}

		.fb-like {
			width: 150px;
		}

		// fix facebook like button to 200px
		.fb-like, .fb-like iframe[style] {width:  200px !important;display:block;z-index:2000;position:relative}
		.fb-like span, .fb-like iframe  span[style] {width: 200px !important;display:block;z-index:2000;position:relative}

		// fix google share button to 200px
		#___plus_1 {
			width: 160px!important;
			iframe {
				width: 160px!important;
			}
		}

		.blog.index {
		}

		div.list-view {
			div.content {
				width: 145px;
			}
		}
		
		.single.post {
			.main.content {
				img {
					max-width: 190px;
					height: auto;
				}
			}
		}

	}
	@media only screen and (min-width: 331px) { // for other phones

		body {
			// min-height: 2200px;
			// min-width: 430px;
			// min-width: 27em;
		}

		body.blank {
			min-width: inherit;
		}

		.mfp-container, .mfp-bg, .mfp-close {
			position: fixed!important;
		}

		// .helper-card.outer.card {
		// 	font-size: 0.8rem;
		// 	min-height: 205px;
		// }
		.helper-card {
			.nickname.title {
				font-size: 1rem;
			}
			.card-matching-detail {
				font-size: 0.8rem;
			}
			.card-back-line {
				font-size: 0.95rem;
			}
			.click-enter {
 			// 	right:15px;
 				font-size:1rem;
 			// 	// bottom:8px;
				// padding:0.5em;
 			// 	position:absolute;
			}
			.add.helper.collection {
				padding:0.5em;
				font-size: 0.7rem;
			}

			.helper_card_phone_text {
				bottom:27px;
			}

			.helper_card_email_text {
				bottom:7px;
			}

		}

	}

		
	/* make-scrollbar-visible-in-mobile-browsers : START */
	::-webkit-scrollbar {
	    -webkit-appearance: none;
	}

	::-webkit-scrollbar:vertical {
	    width: 12px;
	}

	::-webkit-scrollbar:horizontal {
	    height: 12px;
	}

	::-webkit-scrollbar-thumb {
	    background-color: rgba(0, 0, 0, .5);
	    border-radius: 10px;
	    border: 2px solid #ffffff;
	}

	::-webkit-scrollbar-track {
	    border-radius: 10px;
	    background-color: #ffffff; 
	}
	/* make-scrollbar-visible-in-mobile-browsers : END */

	#register_modal {
		top: 15%;
	}
}

@media only screen and (max-width: 330px) { // for iphone
	html.mobile {
		font-size: 0.70em;
	}
}
@media only screen and (min-width: 331px) { // for other phones
	html.mobile {
		// font-size: 0.8em;
		// font-size: 1.2em;
	}
}
