// @doulaeasy_red: rgb(217, 111, 88);
@doulaeasy_red: #e97f68;

@helper_collection_red: #fc3e45;

@doulaeasy_bg_red_lighter: #FFF5F3;
@doulaeasy_bg_red_deeper: darken(@doulaeasy_bg_red_lighter, 3);

@doulaeasy_light_green: #72DA72;

@doulaeasy_item_link: #722f2f;

html {
    -webkit-font-smoothing: antialiased !important;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 63px, 52px;
    // font-size: 16px;
}

html .lockScroll {
	overflow: hidden;
}

html.blank {
	overflow-x: hidden;
	border-radius: 0.3em;
}

body {
	height: 100%;
	margin: 0px;
	font-size: 1rem;
    overflow-x: auto; // added for shrinked browser window
}

body.blank {
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch
}

main {
	display: block
}

.ui.flash.message {
	margin-bottom: 0.6em;
	text-align: center;
}

.ui.simple.circular.label {
	> i {
		margin: 0 0.25em;
	}
}

.ui.menu .item>i.dropdown.icon {
	float: initial;
}

.ui.menu:not(.vertical) .item>.button {
    padding-bottom: 1em;
}

.doulaeasy-logo {
	width: 227px;
	height: 80px;
	margin-top: 2px;
	border: 0px;
}

// .ui.modal {
// 	width: 1050px;
// }

.ui.dimmer {
	z-index: 1200;
}

.main.sidebar {
	background-color: #ffffff;
}


.ui.button.light-green {
	background-color: @doulaeasy_light_green !important;
	color: white!important;
}

p.sub.text {
	font-size: 1.1rem;
	font-weight: normal;
	margin: 0em;
	padding: 0em;
	line-height: 1.2;
	color: #444444;
}

h1.doulaeasy-slogan.text {
	margin-top: 0.6em!important;
	margin-bottom: 0.6em!important;
	font-size: 1.2em;
	font-weight: 400;
}


p.sub.text.small {
	font-size: 1rem;
}

p.light.text {
	margin: 0em 0em 0.3em;
	display: block;
	color: rgba(0, 0, 0, 0.7);
	font-size: 1.1rem;
}

p.light.text.tiny {
	font-size: 0.9rem;
}

#header {
	width:100%;
	z-index: 99999;
}

#front_poster_pic {
	/*margin-top: -34px;*/
	margin-left: -5px;
}

.search h2 {
	margin-top: 0px;
	margin-bottom: 5px;
}

h1.top.banner.title {
	margin-top: 30px;
	margin-bottom: 5px;
}

.top.banner.grid {
	.column {
		margin-top:7px;
		margin-bottom:5px;
		padding-bottom: 10px;
	}
}

h1 {
	font-size: 1.8rem;
}

h2 {
	font-size: 1.3rem;
}
h3 {
	font-size: 1.2rem;
}
.ui.huge.buttons .button, .ui.huge.buttons .or, .ui.huge.button {
	font-size: 1.6rem;
}
.ui.message .header {
	margin: 0em;
	font-size: 1.2rem;
	font-weight: bold;
}

h1, h2, h3, h4, h5, h6, h7 {
	color: #6b4242;
}

.feature_font {
	color: #6b4242;
	font-size: 1.6rem;
	font-weight: 700;
}

.helper.register {
	.ui.form .field > label {
		margin: 0rem 0rem 0.3rem;
		display: block;
		color: #002d14;
		// font-size: 0.95rem;
		font-weight: 700;
	}
}

p.description {
	padding-left:25px;
	padding-right:25px;
}

.ui.helper-profile.segment {
	margin-top: 0em;
	margin-bottom: 0em;
	border-top: none;
}


.search.bar.column {
	margin-top: 0px;
	margin-bottom: 5px;
}

.search.result.column {
	margin-top:5px;
	padding:5px;
}


.ui.column.messages {
	margin-top: 2px;
	margin-bottom: 2px;
}

#footer {
	margin-top: 1em;
	color: #8d6464;
}

#siteseal {
	margin-right: -15px;
}

#footer a, h5 {
	font-size: 14px;
	margin-top: 10px;
	margin-bottom: 5px;
	color: #604444;
	text-decoration: none;
}

.ui.search.form {
	.row {
		margin-bottom:5px;
	}
	label {
		color: rgba(0,0,0,0.8);
	}
	padding-left:10px;
	padding-right:10px;
}

/********** custom for semantic ***************/


/******* Custom for Doula Easy ************/

#prompts_modal.ui.modal {

	height:86% !important;
	top: 7%;
	overflow:auto;

	.ui.tab.segment {
		// height:35em!important;
		// overflow:auto;
	}
}

#register_modal {
	top: 30%;
}

.ui.modal.popup-theme-1 {
	background: transparent!important;
	// width: 70% !important;
  	// margin-left: -35% !important;
  	box-shadow: none !important;
}

.parent_register_button {
	text-decoration: none;
}
.helper_register_button {
	text-decoration: none;
}

.ui.top.attached.menu {
  border-radius: 6px 6px 0px 0px;
}

.ui.inverted.menu .item > a.whitebtn {
  color: #002d14;
}

.dropzone {

	.dz-remove.original-url {
		display: inherit;
	    margin-left: 18%;
	}
	.dz-remove.left-padding {
		display: inherit;
	    margin-left: 25%;
	}
	.dz-remove.left-rotate {
		display: inline;
	    margin-left: 10%;
	}
	.dz-remove.right-rotate {
		display: inline;
	    margin-left: 5%;
	}

	.dz-preview .dz-image img {
	    display: block;
	    height: 100%;
	}

	.dz-preview.ui.label.orange {
		width: 140px;
	}

	.dz-default.dz-message {
	  opacity: 1;
	  -ms-filter: none;
	  filter: none;
	  -webkit-transition: opacity 0.3s ease-in-out;
	  -moz-transition: opacity 0.3s ease-in-out;
	  -o-transition: opacity 0.3s ease-in-out;
	  -ms-transition: opacity 0.3s ease-in-out;
	  transition: opacity 0.3s ease-in-out;
	  background-image: none;
	  background-repeat: no-repeat;
	  background-position: 0 0;
	  position: absolute;
	  width: 428px;
	  height: 123px;
	  margin-left: -214px;
	  margin-top: -61.5px;
	  top: 50%;
	  left: 50%;
	}
}

.dropzone.dz-started .dz-message {
	display: none !important;
}

#search_result_segment {
	padding-top: 0px;
	padding-bottom:0px;
	margin: 0 6px;
}

#search_result_wrapper {
	padding-bottom: 10px;
	padding-top: 8px;
	overflow:hidden;
}

#search_result_content {
	top: 0px;
	position:relative;
}


.ui.helper-item.modal {
	input[name=helper_name] {
		border: none;
	}
}

.ui.selection.dropdown.followed-by.manage-header {
	font-size: 0.8em;
	> .text {
		font-size: 1.3em;
	}
}
.ui.selection.dropdown.followed-by {
	min-width: 120px;
	> .text {
		text-transform: none;
	}
}

.hiring-post {

	.ui.button.add-hiring {
		background-color: rgba(255, 255, 255, 0.65);
	}

	.hiring-post-brief {

		cursor: pointer!important;
		margin-bottom: 0!important;
		min-height: 310px!important;

		>.content {
			min-height: 300px;
		}
		span.paper-clip {
			z-index: 10;
			border: 2px solid #7B3C14;
		}
		span.paper-clip:after {
			z-index: 10;
			border: 2px solid #7B3C14;
    		border-bottom: 0px;
		}
		div.hiring-zone.ui.label {
			text-align: center; padding-top: 0.5em;
		}
	}

	.ui.segment.hiring-board {
		background: url('/assets/images/custom/bg-stitch3.gif');
		box-shadow: grey 0px 3px 8px 1px;
		border: none;

	}

	.ui.cards {
		.hiring-card.card {
			cursor: default;
			box-shadow: grey 1px 3px 16px 1px;
			.strong.meta {
			    color: rgba(0,0,0,.7);
			}
			.meta {
				font-size: 0.85em;
			    color: rgba(0,0,0,.5);
			    .view-button {
			    	padding: 8px;
			    }
			}
			span.hiring-period-focus {
				font-weight: bold;
			}
			div.public-note {
				max-height: 10em;
				overflow: auto;
			}
			div.interested-helpers-div {
				div.interested-helpers {
					max-height: 9em;
					overflow: auto;
				}
			}
			.top-left-corner {
				position: absolute;
			    top: -1.15em;
				left: -1em;
			}
			.role.label {
				padding: 7px;
			}
		}
		.hiring-card.card.editable {
			cursor: pointer;
		}
	}

	.ui.cards.segment {
		margin-left: -0.5em;
		margin-right: -0.5em;
		.ui.top.attached.label:first-child+:not(.attached) {
			margin-top: .875em!important
		}

	    box-shadow: grey 0px 3px 8px 1px;
	    background-clip: padding-box;
	}

	#wished_helpers {
		input[name="note"] {
			display: none;
		}
		.ui.card.helper-item {
			i.edit.icon {
				display: none;
			}
		}
	}

	div#extra_data_hired_helper {
		display: none;
	}
	div#extra_data_preferred_helper {
		display: none;
	}
	div#extra_data_interested_helper {
		display: none;
	}

	#hired_helpers {
		div#extra_data_hired_helper {
			display: block !important;
		}
	}
	#preferred_helpers {
		div#extra_data_preferred_helper {
			display: block !important;
		}
	}
	#interested_helpers {
		div#extra_data_interested_helper {
			display: block !important;
		}
	}

	.hiring-item-board.ui.segment {
		// border: 2px solid rgba(255, 0, 0, .5);
		// border-style: dashed;
	    background-image: url('/assets/images/custom/pinboard1.jpg');
		min-height: 20rem;

		div.ui.cards {
			min-height: 15em;
			overflow: auto;
			position: static
		}

		.ui.top.attached.label:first-child+:not(.attached) {
		    margin-top: 0rem!important;
		}
	}

	.hiring-item-board.ui.segment.read-only {
		// border: 2px solid rgba(255, 0, 0, .5);
		// border-style: dashed;
	    background-image: url('/assets/images/custom/bg-stitch3.gif');
	}

	#hired_helpers.read-only, #preferred_helpers.read-only {
		.ui.card.helper-item {
			i.right.floated.remove.icon {
				display: none;
			}
			i.edit.icon {
				display: none;
			}
		}
	}

	.ui.card.helper-item{

		height: 100%;
		box-shadow: #333333 0px 3px 20px 1px;

		div.ui.numbering.label {

			background: rgba(0, 0, 0, 0.27);
			color: white;
		    font-weight: bold;
		    border: none;
		    font-size: 0.75em;
		    padding-left: 0.8em;
		    border-radius: 0.2em 0em;

			> span.number {
				font-size: 1.2em;
			}
		}

		i.right.floated.remove.icon {
			margin-right: -0.2em;
		    position: absolute;
			bottom: 0.6em;
			right: 0.5em;
			font-size: 1.3em;
		}
		i.edit.icon {
			margin-bottom:-0.5em;
		    position: absolute;
			bottom: 1em;
			font-size: 1.3em;
			// position: absolute;
			// bottom: 1em;
			// font-size: 0.8em;
		}
		.content {
			font-size: 0.8em;
			padding: .5em;
			word-break: break-word;
			.header {
				font-size: 1.3em;
				padding: 0.3em;
			}
			textarea {
				font-size: 0.8em;
			    padding: 2px;
			    min-height: 4em;
			    height: 4em;
			}
			input {
				font-size: 0.8em;
			    padding: 2px;
			    // min-height: 4em;
			    // height: 4em;
			}
		}
	}
}

.hirer h2 {
	margin-top: 10px;
	margin-bottom: 10px;
}

#loading_result {
	display:none;
	position:absolute;
	top:20px;
	width:100%;
	margin-left: 0px;
	margin-right: 0px;
}

div.helper-profile.ui.modal {
	overflow: hidden;
    height: 86%!important;
    top: 6%;
	#helper_profile_iframe {
		overflow-x: hidden;
		overflow-y: hidden;
	}
}


iframe {
	border: 0px inset;
	width: 100%;
	border-image-source: initial;
	border-image-slice: initial;
	border-image-width: initial;
	border-image-outset: initial;
	border-image-repeat: initial;
}


.ui.menu .avatar.dropdown {
	z-index: 200;
	padding-top:3px;
	padding-bottom:3px;
}

.ui.menu .avatar.thumbnail {
	padding:3px;
	padding-right:10px;
}

.ui.user-avatar.image {
	border-radius: 0.4em;
}

.ui.pagination.menu {
	padding-left: 0;
}


i.dark.teal.icon {
	color: darken(#00B5AD, 8%) !important;
}
.ui.dark.teal.label {
  background-color: darken(#00B5AD, 8%) !important;
  color: #FFFFFF !important;
}
.ui.dark.teal.button {
  background-color: darken(#00B5AD, 8%) !important;
  color: #FFFFFF !important;
}
.ui.dark.teal.button:active {
  background-color: darken(#00B5AD, 8%) !important;
  color: #FFFFFF !important;
}

.ui.dark.teal.role.label {
  background-color: darken(#00B5AD, 8%) !important;
  color: #FFFFFF !important;
}
.ui.dark.teal.role.button {
  background-color: darken(#00B5AD, 8%) !important;
  color: #FFFFFF !important;
}

// i.dark.blue.icon {
// 	color: darken(#6ECFF5, 25%) !important;
// }
// .ui.dark.blue.label {
//   background-color: darken(#6ECFF5, 30%) !important;
//   color: #FFFFFF !important;
// }
// .ui.dark.blue.button {
//   background-color: darken(#6ECFF5, 30%) !important;
//   color: #FFFFFF !important;
// }
// .ui.dark.blue.button:active {
//   background-color: darken(#6ECFF5, 30%) !important;
//   color: #FFFFFF !important;
// }
// .ui.dark.blue.role.label {
//   background-color: darken(#6ECFF5, 22%) !important;
//   color: #FFFFFF !important;
// }
// .ui.dark.blue.role.button {
//   background-color: darken(#6ECFF5, 22%) !important;
//   color: #FFFFFF !important;
// }

.ui.light.orange.button {
  background-color: #f09a78 !important;
  color: #FFFFFF !important;
}
.ui.light.orange.button:active {
  background-color: #E96633 !important;
  color: #FFFFFF !important;
}

.ui.light.red.button {
  background-color: #de7171 !important;
  color: #FFFFFF !important;
}
.ui.light.red.button:active {
  background-color: #D95C5C !important;
  color: #FFFFFF !important;
}

.ui.dividing.header {
	padding:5px;
}

#my-avatar-dropzone {
	min-height: 217px;
}
#my-doula-certificate-dropzone {
	min-height: 217px;
}
#my-babysitter-certificate-dropzone {
	min-height: 217px;
}
#my-photo-dropzone {
	min-height: 217px;
}
#my-qualification-dropzone {
	min-height: 217px;
}
.receipt.dropzone {
	min-height: 217px;
}
.ui.badge.segment {
	.dropzone {
		min-height: 217px;
		.dz-message {
			margin-top: 40%!important;
			margin-left: 25%!important;
		}
	}
}

.ui.avatar.segment {
	// height: 249px;
	width: 224px;
}

.ui.doula.certificate.segment {
	// height: 249px;
	width: 235px;
}

.ui.babysitter.certificate.segment {
	// height: 249px;
	width: 235px;
}

.ui.receipt.segment {
	// height: 249px;
	width: 235px;
}

.ui.badge.segment {
	// height: 250px;
	width: 250px;
}

.others.label.text {
	color: rgba(0,0,0,0.6);
}

#boxes {
	padding-bottom: 1em;
	.ad.item {
		// width: 30%;
		min-height: 0px;
		background: inherit;
		border: inherit;
		box-shadow: inherit;
		margin-top: 0em;
		margin-bottom: 0.7em;

		img {
			border-radius: 0.25em;
		}
	}

	.item:nth-child(3n+1) {
		clear: none;
	}

	.helper-card.outer.card {
		min-height: 225px;
		max-height: 257px!important;
		padding: 0px;
		// margin-left: 0.5em!important;
		margin-top: 0em!important;
		margin-bottom: 0em!important;
		background: none;
		box-shadow: none !important;
		border-bottom: none;
	}

	.helper-card {

		/* entire container, keeps perspective */
		.flip-container {
			perspective: 1000;

			/* flip speed goes here */
			.flipper {
				transition: 0.6s;
				transform-style: preserve-3d;

				position: relative;
			}

			/* hide back of pane during swap */
			.front, .back {
				backface-visibility: hidden;
				position: absolute;
				top: 0;
				left: 0;
			}

			/* front pane, placed above back */
			.front {
				z-index: 2;
				/* for firefox 31 */
				transform: rotateY(0deg);
			}

			/* back, initially hidden pane */
			.back {
				transform: rotateY(180deg);
			}

		}
		/* flip the pane when hovered */
		.flip-container.hover .flipper {
			transform: rotateY(180deg);
		}

		.flip-container, .front, .back {
			width: 21em;
			height: 18em;
			margin: auto;
		}

		.ui.cards .card {
			cursor: pointer;
			// width: 335px;
			width: 21em;
			height: 15em;
			min-height: 225px;
			margin: 0;
			// left:4px;
			margin-bottom: 2em;
			overflow: hidden;
			text-transform: none;
			background-color:white!important;
			border-color:white;
		}

		.right.floated.circular.icon {
			margin-top: -10px;
		}

		i.coffee.icon {
			margin-top: 5px;
			cursor: pointer;
			> a.red.circular.label {
				position: relative;
				top: -4.4em;
			 	right: -1.3em;
			 	font-size: .5rem;
			 	padding: 1px 5px!important;
			}
		}

		// i.phone.square.icon {
		// 	margin-top: 3px;
		// 	cursor: pointer;
		// 	font-size: 1.4em;
		// }
		// i.mail.outline.icon {
		// 	margin-top: 3px;
		// 	cursor: pointer;
		// 	font-size: 1.4em;
		// }
		// i.comment.outline.icon {
		// 	margin-top: 2px;
		// 	cursor: pointer;
		// 	font-size: 1.4em;
		// }
		// i.photo.icon {
		// 	margin-top: 3px;
		// 	cursor: pointer;
		// 	font-size: 1.4em;
		// }
		// i.certificate.icon {
		// 	margin-top: 3px;
		// 	cursor: pointer;
		// 	font-size: 1.4em;
		// }

		i.badge-popup.icon {
			margin-top: 3px;
			cursor: pointer;
			font-size: 1.15em;
		}

		img.badge {
			opacity: 0.8;
			height: 3em;
			width: 3em;
			margin-bottom: 0em;
			margin-top: -0.1em;
			vertical-align: bottom;
			// position: absolute;
			// right: 15px;
			// top: 15px;
			// z-index: -1;
		}

		.click-enter {
			margin-left: 10px;
			.click-enter-text {
				cursor: pointer;
			}
		}

		.front.side {

			div.main.content {

				padding-top: 0em;

				div.upper-content.grid {

					margin-top: 0;

					div.left-section-column {
						.rounded-avatar {
							img {
								border-radius: .2125em;
								height: 140px;
				  				width: 120px;
							}
						}
					}

					div.right-section-column {
						padding: 0.5em;

						div.helper-roles {
							margin-top: 10px;

							div.left-column {
								padding-right: 0em;
							}

							div.right-column {
								padding-left: 0em;
							}

							div.doula.row {
								padding-top: 0px;
								padding-bottom: 5px;
							}
							div.babysitter.row {
								padding-top: 0px;
								padding-bottom: 5px;
							}
						}

						.nickname.title {
							color: #6b4242;
							font-size: 1.4rem;
							font-weight: 100;
							margin-left:5px;
							margin-top:0px;
							vertical-align: top;
						}
					}
				}

			}

			div.extra.content {

				height: 3.3em;

				// div.icon-rack.grid {

				// 	.coffee-column {
				// 		height: 2.4rem;
				// 	}
				// 	i.coffee.icon {
				// 		padding-top: 3px;
				// 		// padding: 0px;
				// 		margin: inherit;
				// 		font-size: 1.5rem!important;
				// 	}
				// 	a.coffee-num {
				// 	    opacity: 1;
				// 	    font-size: .5rem;
				// 	    top: -35px;
				// 		right: 11px;
				// 		position: relative;
				// 	}
				// }
			}

			.viewed-label {
				border-top-color: blanchedalmond;
				color: rgb(107, 168, 168);
				display: none;
			}

			.helper_card_phone_text {
				padding:0px;
				left:0px;
				font-size:0.8rem;
				bottom:22px;
				position:absolute;
			}

			.helper_card_email_text {
				padding:0px;
				left:0px;
				font-size:0.8rem;
				bottom:3px;
				position:absolute;
			}
			.card-matching-detail {
				color:black;
				padding-left:5px;
				margin-top:2px;
				margin-bottom:0px;
				font-size:0.9rem;
			}
			.parent_register_text {
				color: black;
				font-size:0.9em;
			}
			.parent_register_button {
				font-size: 0.9em;
				i {
					margin: 0 5px !important;
				}
			}
			.ui.label a {
				opacity: 1;
			}
			span.bottom-instruction {
				color: #222222;
				font-size: 0.9em;
			}
		}

		.back.side {


			.top.left.name.label {
				padding-top: 5px;
				padding-bottom: 5px;
			}

			div.extra.content {
				height: 3.3em;
			}

			div.content > div.description {
				> div.ui.grid {
					height: 12em;
				}
			}

			.card-back-line {
				color:black;
				padding:0px;
				// line-height: 0.9em;
				// font-size:0.9rem;
			}
			.parent_register_button {
				color:black;
				font-size:0.9rem;
				position: absolute;
				bottom: 0.5rem;
				left: 1rem;
			}

		}
	}

}


.dropzone a.dz-remove, .dropzone-previews a.dz-remove {
	margin-top: 8px;
}

.dz-details {
	margin-left: auto;
	margin-right: auto;
}

.ui.html.selection.dropdown {
	width: 300px;
	margin-left: 10px;
}

@media only screen and (min-width: 992px) {
	.ui.search.dropdown .menu {
	    max-height: 20rem;
	}
	.ui.selection.dropdown .menu {
	    max-height: 20rem;
	}
}

.ui.dropdown.hong-kong-district {
  min-width: 10em;
}
.ui.dropdown.organization {
  min-width: 8em;
}

.ui.menu.attached {
margin: 0rem;
box-shadow: 0px 0px 0px 0px #DDDDDD;
}

.ui.service.expiry.message {
	padding: 30px;
}

.ui.main.inner.page.segment {
	background-color: #FFF;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 0.5em;
}

.ui-widget-content {
	background: white;
}

.ui-widget-header {
	font-weight: normal;
	background: @doulaeasy_red;
	border: none;
}

.ui-slider .ui-slider-range {
	background: #8ada9d 50% 50% repeat-x;
}
.ui-slider .ui-state-active{
	background: inherit;
}
.ui-slider .ui-state-focus {
	background: #f6f6f6 url("images/ui-bg_glass_100_f6f6f6_1x400.png") 50% 50% repeat-x
}
.ui-slider .ui-state-hover {
	background: #f6f6f6 url("images/ui-bg_glass_100_f6f6f6_1x400.png") 50% 50% repeat-x
}

.ui-datepicker {
	margin-left: auto;
	margin-right: auto;

	.ui-datepicker-year {
		width: 70px !important;
	}

	select {
		color: black;
	}

	.ui-datepicker-month {
		margin-left: 10px !important;
		width: 80px !important;
	}

	.unselectable a {
		cursor: default;
		border: 1px solid #3972B2;
		/*background: #f6f6f6 url("images/ui-bg_glass_100_f6f6f6_1x400.png") 50% 50% repeat-x;*/
		color: #3972B2;
		font-weight: bold;
	}
	.ui-selectee a {
		font-weight: bold;
	}

	.ui-state-active {
		background: inherit;
	}

	.ui-state-default {
		background: white;
	}

	.ui-state-disabled {
		font-weight: normal;
	}
	.outer-highlight a {
		background: #e79b9b;
		color: #363636;
	}
	.ui-selected a {
		background: #e79b9b;
		color: #363636;
	}
	.outer-highlight {
		border: none;
		background: none
	}

	.ui-datepicker-row-break {
		height: 10px;
	}

}
.ui-datepicker.ui-widget {
	font-size: 1rem;
}
#ui-datepicker-div { z-index: 999999!important; }

.no-prev-next .ui-datepicker .ui-datepicker-prev,.ui-datepicker-next {display:none;}

#helper_schedule_calendar .ui-datepicker {
	// width: 40rem !important;
	// width: 650px !important;
}

.required:after { content:" *"; color: red }

#___plusone_0{
	width:35px !important;
}

#___plus_0{
	width:60px !important;
}


.ui.inverted.menu .dropdown.item.highlight {
	background-color: rgba(255,255,255,0.2);
}
.ui.inverted.menu a.item.highlight:hover, .ui.inverted.menu .dropdown.item.highlight:hover {
	background-color: rgba(255,255,255,0.1);
}

.ui.content.form > .breadcrumb{
	margin:0px;
}

.ui.content.form > .divider{
	margin:0px;
}

.image-source-link {
	color: #98C3D1;
}

.magnific-image {
	display: inline;
	a {
		cursor: -webkit-zoom-in;
		cursor: -moz-zoom-in;
		cursor: zoom-in;
	}
}


.ui.label:not(.corner) {
	padding-top: 0.25rem;
	padding-bottom: 0.3rem;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	line-height: 1rem;
	background-color: rgb(252,246,242);
	color: rgba(152, 59, 36, 1);
	text-transform: none;
	border: 1px solid rgba(0, 0, 0, 0.09);
}
.ui.ribbon.label {
	padding: .5em .8em;
}

.ui.form  {
	.changed input {
		background-color: #FEC154;
		// color: #FFFFFF;
	}
	label.changed {
		background-color: #FEC154;
		// color: #FFFFFF;
	}
}

span.disabled, label.disabled {
  opacity: .4;
  color: rgba(0,0,0,.3);
}

.ui-slider.disabled {
	opacity: 0.4;
}

.ui.signin.form {
	.field {
		margin-top: 1em;
	}
}

.signin.page {

	.facebook.button {
		width:12em;
	}
	.google.plus.button {
		width:12em;
	}

	div.ui.form.segment {
		padding: 0.5em;
	}

	div.right.floated.text-link {
		right: 2em;
		position: absolute;
	}

	#login_section {
		div.ui.input.login-input {
			width: 15em;
		}
		div.parent-role-button {
			width: 7em;
			// background-color: rgb(255, 158, 135);
  			// color: white;
		}
		div.helper-role-button {
			// background-color: rgb(110, 171, 226);
  			// color: white;
		}

		.ui.buttons .or:before {
		  content: '或';
		  font-size: 0.9em;
		}
	}
}

.red_text {
	color: #d54c4c !important;
}
.ui.inverted.menu .dropdown .menu .red_text.item, .ui.inverted.menu .dropdown .menu .red_text.item a {
	color: #d54c4c !important;
}

.ui.form textarea {
	min-height: 50px;
	height: auto;
	font-family: "Helvetica Neue", "Helvetica", Arial;
}

i.icon.hiring-type {
	font-family: "微軟正黑體";
}

// .ui.huge.labels .label, .ui.huge.label {
// 	left: -2.15rem;
// }

.ui.shaded.checkbox label {
    cursor: default;
    opacity: .5;
    color: #000;
}

/* made for helper profile blank red segment */
.display_none {
	display:none !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently
                                  not supported by any browser */
}

.ui.buttons .active.button, .ui.active.button {
	background-color: #DFDFDF;
}

.ui.search .result .title {
	font-weight: 100;
}

.ui.input input {
	font-family: inherit;
}


.ui.input input.disabled {
	color: rgba(0,0,0,.3);
}

.cke_editor_self_introduction {
	margin-left: auto !important;
	margin-right: auto !important;
}

i.dark.green.icon {
	color: darken(#A1CF64, 20%) !important;
}

i.dark.purple.icon {
	color: darken(#564F8A, 10%) !important;
}

i.dark.teal.icon {
	color: darken(#00B5AD, 10%) !important;
}

#status_labels {
	// margin-top: -9px;
	background: #bfddaf;

	border-bottom: 1px solid #DDD;
	margin-top: 5px;
	margin-bottom: 8px;
	// margin-left:10px;
	// margin-right:10px;
	border-radius: 5px;

	div.top.column {
		padding: 0.5em;

		#email_confirmation_alert {
			> div.row {
				  padding: 0 5px !important;
			}
			margin-top: 1em;
    		margin-bottom: 0.3em;
		}
	}

	.ui.label {
		background-color: #fcfcfc;
		a {
			text-decoration: none;
		}
	}
	.ui.right.floated.header {
		margin: 0px;
	}
}

a.ui.label {
	text-decoration: none;
}

.role.button {
	font-weight:100 !important;
	font-size: 1.2rem !important;
	padding-left: 0.7em;
	padding-right: 0.7em;
	padding-top: 0.6em;
	padding-bottom: 0.6em;
	margin-left: 5px;
}

.ui.header .label .icon {
	padding-right: 0px;
	margin-right: 0px;
}


a.icon {
	text-decoration: inherit;
	color: inherit;
}

.ui.inverted.red.menu {
	background-color: @doulaeasy_red;
}
.ui.inverted.red.segment {
	background-color: @doulaeasy_red!important;
	color: #FFF!important;
}
.ui.red.label, .ui.red.labels .label {
	background-color: @doulaeasy_red!important;
	border-color: @doulaeasy_red!important;
	color: #FFF!important;
}

.ui.red.inverted.accordion.menu .title {
	color: #FFF;
}

.ui.red.inverted.accordion.menu {
	font-size: 1.1rem;
}

.ui.accordion .accordion .title, .ui.accordion .title {
	padding: 0px;
}
.ui.accordion .accordion .content, .ui.accordion .content {
	padding: 0px;
}

// div.district.selector.ui.modal {
// 	height: 85%!important;
// }

.helper-profile {
	// .ui.page.grid {
	// 	padding: 0 50px;
	// }

	div#reveal_phone_button {
		cursor: pointer;
	}

	div#reveal_email_button {
		cursor: pointer;
	}

	.parent_register_text {
		color: black;
		font-size:0.9em;
		margin-left: 0.5em;
	}
	#helper_show_inner_div {
		// width: 999px;
		margin-top: 10px;
	  	padding-top: 10px;
		max-width: none;
	}
	.reference-map {
		font-size: 0.85rem;
	}
	.region {
		margin-bottom: 8px;
		.region-title {
			padding-bottom: 2px;
		}
		.district {
			padding-left: 5px;
			color: #555555;
			font-size: 0.85rem;
			white-space: nowrap;
		}
	}
	.email {
		word-wrap: break-word;
		font-size: 0.9rem;
	}
	.ad.show.profile {
		text-align: center;
	}
	img.badge {
		margin-left: 7px;
	}
	.organization.name {
		top: -10px;
		position: relative;
		vertical-align: top;
	}
	.parent_register_button {
	  // font-size: 0.9em;
  	  text-decoration: none;
  	}
}

div.more-info-fields.ui.grid {
	.ui.dropdown>.default.text {
		color: black;
	}
}

// .ui.buttons .button:first-child {
// 	margin-left: 5px;
// }

#btn_login {
	width: 7em;
	// height: 2.62em;
}
#btn_register {
	width: 7em;
	// height: 2.62em;
}

#facebook_like_column {
	// height: 75px;
	// width: 130px;
	padding-top:0px!important;
	padding-bottom:0px!important;
}

#google_plusone_column {
	// height: 18px;
	// width: 70px;
	padding-left:0px!important;
	padding-right:0px!important;
	padding-top:0px!important;
	padding-bottom:0px!important;
}

.social.share.column {
	width: 48%!important;
}

.ui.form .inline.field>input{
	width: 100%;
}

.ui.edit.form .field {
	clear: both;
	margin: 0 0 0.3em;
}

.auto_margin_center {
	margin-left: auto!important;
	margin-right: auto!important;
}

// fix facebook comments width to 0px problem
.fb-comments, .fb-comments span, .fb-comments iframe {
	width: 100% !important;
}

// .fb-like {
// 	width: 350px;
// }
.g-plus {
	width: 350px;
}

// wordpress styling for blog system
.ui.small.segment {
	padding-bottom: 0.3em;

	.ui.selection.list {
		margin-top: 1.6em;
	}
}
.ui.cards {
	a {
		color: inherit;
		text-decoration: inherit;
	}

	a.default-link {
		color: #007ba9;;
	}
}
.ui.breadcrumb a {
	text-decoration: inherit;
}
.item {
	.video-container iframe,
	.video-container object,
	.video-container embed {
	    // position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 14em;
	}
}
.video-container {
	text-align: center;
}

// wordpress align css
.aligncenter, img.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.alignright {
	float: right;
	margin: 7px 0 7px 24px;
}
.alignleft {
	float: left;
	margin: 7px 24px 7px 0;
}

.single.post {
	.post.content {
		padding: 5px;
		section.main.content {
			padding-left: 40px;
			padding-right: 40px;
		}
		.name a {
			color: rgba(0,0,0,.7);
			text-decoration: none;
		}
		h2.name {
			margin-bottom: 0px;
		}
		div.author {
			float:right;
		}
		div.terminology {
			margin-top: 12px;
			span.category {
				margin-left:3px;
			}
			span.tag {
				margin-left:3px;
			}
		}
		div.datetime {
			margin-top: 7px;
			span.date {
				margin-left:3px;
			}
			span.time {
				margin-left:3px;
			}
		}
		.social.elements {
			// height: 53px;
			margin-left:3px;
			margin-top:8px;
			.fb-like {
				height: 25px;
				margin-left:1px;
			}
		}
		p.event-location {
			margin-bottom: 5px;
		}
		.street.address {
			padding: 10px;
		}
		p.event-reference-url {
			a {
				word-break: break-all;
			}
		}

	}
	.pagination.menu {
		margin-top: 1rem;
		padding: 4px;
		.item {
			padding: 0.5em;
		}
	}
	.about.author {
		h4 {
			margin-top: 5px;
			margin-bottom: 0px;
		}
		.description {
			margin-top: 8px;
			padding-left: 5px;
			padding-right: 5px;
		}
		.website.link {
			font-size: 0.9rem;
		}
	}
}


.side-panel .ui.segment>.attached.label:first-child+* {
	margin-top: 0em;
}


.red-hover-border-image  {
	> img {
		border-radius: 1em;
  		border: solid 3px rgba(300,200,200,0.55);
  	}
	> img:hover {
		border-radius: 1em;
  		border: solid 3px darken(rgba(300,200,200,0.55),25%);
  	}
}
.blue-hover-border-image {
	> img {
		border-radius: 1em;
		border: solid 3px rgba(200,200,300,0.55);
		}
	> img:hover {
		border-radius: 1em;
  		border: solid 3px darken(rgba(200,200,300,0.55),25%);
  	}
}

.home.content {

	.ui.list {
		margin-top: 0em;
		margin-bottom: 0em;
	}
	h3 {
		margin-bottom: 0.3em;
	}
	.ui.segment {
		padding-bottom: 0.3em;
		padding-top: 0.6em;
	}
	i.icon {
		font-size: 1.1rem;
	}
	// .ui.list .item>.content {
	// 	width: 15em; // changed to 15em due to firefox
	// 	display: inline-block;
	// 	vertical-align: middle;
	// 	line-height: 1.2em;
	// }
	.ad.item {
		margin-top:5px;
		// width: 370px;
		overflow: hidden;
	}

	.hot-questions.ui.segment {
		// background: -webkit-linear-gradient(rgba(255,255,255,.40) 0,
		// 	rgba(255,255,255,.47) 100%)#717579;
  //   	background: linear-gradient(rgba(255,255,255,.40) 0,
  //   		rgba(255,255,255,.47) 100%)#717579;
		h3 {
			text-align: left;
			margin-top: 0em;
			margin-bottom: 0.2em;
			// color: white;
		}
		img.question-zone-logo {
			max-width: 120px;
		}
		a.header {
			text-align: left;
			// color: white!important;
		}
		a.title {
		    text-decoration: inherit;
			// color: white;
		}
		a.red.title {
		    color: @doulaeasy_red;
		    float: right;
		}
		span.ask-time {
			// color: #F1F3CF;
		}
		.ui.list {
			>.item {
				.content {
					width: 88%;
					.description {
						margin-top: 0.3em;
					    font-size: 0.9em;
					    text-align: right;
						// color: white;
					}
				}
			}
		}
		div.mCustomScrollbar {
			margin-right: -8px;
   			margin-left: -8px;
   			height:3.6em;
   			overflow: hidden;

   			div.mCSB_inside > .mCSB_container {
   				margin-right: 15px;
   			}

			div.mCSB_scrollTools_vertical {
				margin-right: -5px;
			}
		}

		.qa-ask-box {
			.qa-form-tall-table {
				width:100%;
				> tr {
					vertical-align:middle;
				}
				.qa-form-tall-label {
					padding:8px;
					white-space:nowrap;
					text-align:right;
				}
				.qa-form-tall-data {
					padding:8px;
					height: 3em;
					width: 100%;
					.qa-form-tall-text {
						width:95%;
					}
				}
			}
		}
	}

	.articles.listing {
		.ui.items {
			margin-top: 1.1em;
			margin-bottom: 0em;
			.content {
				.header {
					font-size: 1em;
					width: 100%;
					max-height: 1.2em;
    				overflow: hidden;
				}
				.description {
				 	margin-top: .2em;
				}
			}
			.item {
				min-height: 75px;
				// margin-top: 0.5em;
				// margin-bottom: 0em;
				.ui.mini.image, .ui.mini.images .image, .ui.mini.images img {
					height: 52px;
					width: 52px;
				}
				.image {
					height: 52px!important;
					width: 52px!important;
					background-color:inherit;
					margin-left: 0.2em;
  					margin-right: 0.5em;
				}
				.icon.play {
				    position: relative;
				    top: -35px;
				    color: white;
				    left: 16px;
				    height: 0px;
				}
			}

			.image+.content {
				padding-left: 0.8em;
			}
		}

		.post-description {
			font-size: 1rem;
		}
		a.title {
			text-decoration: inherit;
			color: inherit;
		}
		span.start-date, time.start-date {
			float:right;
			font-size: 0.8rem;
			font-weight: 100;
			// font-style: italic;
		}
		.articles-div {
		    padding-bottom: 1.5em!important;
		    padding-top: 1.2em;
		    height: 29em;
    		overflow: auto;
    		margin: 0!important;
		}
		.tabular.fitted.menu {
			font-size: .895rem;
			>.item {
				padding:3px;
			}
		}
	}
	.articles-div {
		.know-more-div {
			height: 1.2em;
			position: absolute;
			bottom: 10px;
			right: 10px;
			.know-more {
				font-size: 0.9rem;
				float:right;
			}
		}
	}

}

.ui.selection.list .item {
	color: rgba(0,0,0,.85);
	padding: .3em;
}

.ui.doulaeasy.label {
	margin-top: 2px;
}


#findus_whatsapp {
	position: fixed;
    bottom: 40px;
    right: 23px;
	background: #25d366;
	height: 55px;
	width: 55px;
	line-height: 39px;
	z-index: 100;
	text-align: center;
	color: #fff;
	border-radius: 15px;
	box-shadow: grey 1px 1px 6px 0px;
}
#findus_whatsapp:hover{
	color: #fff;
	background: #21BD5B;
}
#findus_whatsapp > i{
	position: relative;
	font-style: normal;
	vertical-align: middle;
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	line-height: 50px;
	z-index: 100;
	font-size: 37px;
	text-align: center;
	color: #fff;
}

.brown.icon {
	color: #885b35 ;
}


.item-link {
	color: inherit;
	text-decoration: inherit;
}


.search.bar {
	.ui.outer.segment {
		// margin-top: 1em;
		margin: 0px;
		padding-top:1.1em;
		padding-bottom: 1.5em;
		padding-left:0.6em;
		padding-right:0.6em;
		margin-left: 1em;
		margin-right: 1em;
		border-top: 0em solid #e97f68;
		box-shadow: -3px 3px 8px 1px rgb(210, 210, 210);
	}

	#helper_collection {
		padding-left: 0.5em;
		padding-right: 0.5em;
	}
	#find_twenty_four_hour {
		padding-left: 0.5em;
		padding-right: 0.5em;
	}

	.helper-collection-column {
		padding-left: 10px !important;
		padding-right: 3px !important;
	}

	.find-twenty-four-hour-column {
		padding-left: 3px !important;
		padding-right: 10px !important;
	}

	#find_twenty_four_hour.purple .ui.moon  {
		color: yellow!important;
	}

	::-webkit-input-placeholder { /* WebKit browsers */
		color: rgba(0,0,0,0.55)!important;
		font-weight: bold;
	}
	:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
	   color: rgb(0,0,0)!important;
	   opacity:  0.55;
		font-weight: bold;
	}
	::-moz-placeholder { /* Mozilla Firefox 19+ */
	   color: rgb(0,0,0)!important;
	   opacity:  0.55;
		font-weight: bold;
	}
	:-ms-input-placeholder { /* Internet Explorer 10+ */
	   color: rgba(0,0,0,0.55)!important;
		font-weight: bold;
	}
	#jump_to_top_button {
		position: absolute;
		z-index: 1;
		bottom: 5px;
		right: 32px;
	}
	#reset_form_button {
		position: absolute;
		z-index: 1;
		bottom: 5px;
		right: 5px;
	}
	.ui.button {
		.visible.content {
			margin: auto;
		}
	}
}

#search_bar {
	transition: all 1s ease;
}

#search_bar.scroll-fixed {
	z-index: 1000!important;
	left: 0px!important;
	right: 0px!important;
	// width: 100% !important;
	position: fixed;
  	margin-left: 0!important;
  	margin-right: 0!important;
}

.ui-slider {
	margin: 5px 5px 0 5px;
}

span.paper-clip:after {
    width: 8px;
    height: 20px;
    content: " ";
    transform: rotate(4deg);
    -webkit-transform: rotate(4deg);
    // background: white;
    display: block;
    position: absolute;
    right: 2px;
    top: 5px;
    border-radius: 10px;
    border: 2px solid #666666;
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
span.paper-clip {
    // background: white;
    height: 40px;
    width: 13px;
    border-radius: 10px;
    border: 2px solid #666666;
    display: inline-block;
    position: absolute;

    top: -15px;
	right: 16px;
	transform: rotate(25deg);
    -webkit-transform: rotate(25deg);
}

.press.release {
	h3 {
		margin-top: 0.4em;
		margin-bottom: 1em;
	}
	p {
		margin-top: 5px;
		margin-bottom: 5px;
	}
	.ui.segment {
		padding-bottom: 1em;
	}
}

#search_icon {
	margin-left: 4px;
}


#email_sender {
	textarea {
		width: 90%;
	}
}

.blog.page {
	h2.page.title {
		margin-top: 0.6em;
		margin-bottom: 0.6em;
	}
	.main-content {
		padding-left: 2em;
		.content.page {
			padding: 2em;
		}
	}
}

.blog.index {
}


div.gallery-view {
	.tag {
		margin-left: 5px;
	}
	.datetime {
		margin-left: 5px;
	}
	iframe {
		margin-top: 10px;
	}

	.card>.image {
		background: transparent !important;
	}
}
div.list-view {
	margin-top: 0px;
	.header {
		padding-bottom: 0.3em;
	}
	.post-description {
		padding-bottom: 0.4em;
	}
	.ui.mini.image, .ui.mini.images .image, .ui.mini.images img {
		height: 65px;
		width: 65px;
	}
	.image.container {
		height: 65px;
		width: 65px;
		background-color:inherit;
		margin-right: 8px;
	}
	.icon.play {
	    position: relative;
	    top: -42px;
	    color: white;
	    left: 25px;
	    height: 0px;
	}
	.icon.large.play {
	    position: relative;
	    top: -55px;
	    color: white;
	    left: 20px;
	    height: 0px;
	}
	div.content {
		line-height: 1.5em;
		width:85%;
		.meta {
			float: right;
		}
	}
	.tag {
		margin-left: 5px;
	}
}
.ui.divided.list:not(.horizontal) .icon.list {
	margin: inherit;
	padding: inherit;
}

// advanced custom field: google map
.acf-map {
	width: 100%;
	height: 250px;
	border: #ccc solid 1px;
	margin: 0px 0 15px;
}

.ui.segment.event.details {
	padding-left: 2em;
	padding-right: 2em;
	padding-bottom: 1.5em;
}

@-webkit-keyframes rotate {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}

@keyframes rotate {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}

// .loading-icon {
//     -webkit-animation:rotate 2.2s linear infinite;
//     -moz-animation:rotate 2.2s linear infinite;
//     animation:rotate 2.2s linear infinite;
// }
.ui.loader:after {
	border-color: #E5DBDB transparent transparent;
}


blockquote {
	font-style: italic;
}

.powerpoint-iframe {
	width: 90%;
}

.ui.lightpink.label {
	background-color: rgb(255, 237, 237);
}

.add.helper.collection.label {
	border: 1px solid pink;
}

.side-panel {
	*[id^='oio-banner-'] { // id that start with oio-banner-
		max-width: inherit!important;
		text-align: center;
	}
	.ad.item {
		margin-top: 10px;
	}
}

.default-300x250-ad-banner {
	background-image: url(https://farm8.staticflickr.com/7514/15812010005_8c00d0c1c2_o.png);
	height: 250px;
	border-radius: 0.25em;
}

#toggle_desktop_button {
	float: right;
	text-decoration: none;
	font-size: 1em;
	margin-right: 0.5em
}
.blank #helper_show_inner_div{
	margin-top: 0px;
}

span.suggested-price {
	font-size: 0.85em;
}

// .infobox-popup {
// 	font-size:0.5em!important;
// }

.ui.list.organization {
	li:before {
		top: 1em;
	}
	.text-description {
		vertical-align: super;
		margin-left: 8px;
		position: relative;
		top: -8px;
	}
}
.divclearable {
    width: 100%;
}

table.ui.table {
	div.hidden-columns {
		display: inline;
		opacity: 0.8;
	}
}

div.row.center.aligned {
	text-align: center;
}

.ui.tagging.dropdown.labeled.button {
	min-width:11em;
	padding-left: 3.6em!important;
	> .ui.label {
		border-top-left-radius: inherit!important;
		border-bottom-left-radius: inherit!important;
		margin: 2px!important;
	}
}

div.captcha-img {
	> img {
		cursor: pointer;
	}
	div.ui.input {
		vertical-align: top!important;
		width: 120px!important;
		margin-left: 5px!important;
	}
}

a.item-link {
	color: @doulaeasy_item_link;
}

/* class for stuffing space: START */
.margin-0 {
	margin: 0px!important;
}
.margin-5 {
	margin: 5px!important;
}
.margin-10 {
	margin: 10px!important;
}

.margin-top-0 {
	margin-top: 0px!important;
}
.margin-top-5 {
	margin-top: 5px!important;
}
.margin-top-10 {
	margin-top: 10px!important;
}
.margin-top-15 {
	margin-top: 15px!important;
}
.margin-top-20 {
	margin-top: 20px!important;
}

.margin-bottom-0 {
	margin-bottom: 0px!important;
}
.margin-bottom-5 {
	margin-bottom: 5px!important;
}
.margin-bottom-10 {
	margin-bottom: 10px!important;
}
.margin-bottom-15 {
	margin-bottom: 15px!important;
}
.margin-bottom-20 {
	margin-bottom: 20px!important;
}

.margin-left-0 {
	margin-left: 0px!important;
}
.margin-left-5 {
	margin-left: 5px!important;
}
.margin-left-10 {
	margin-left: 10px!important;
}
.margin-left-15 {
	margin-left: 15px!important;
}
.margin-left-20 {
	margin-left: 20px!important;
}

.margin-right-0 {
	margin-right: 0px!important;
}
.margin-right-5 {
	margin-right: 5px!important;
}
.margin-right-10 {
	margin-right: 10px!important;
}
.margin-right-15 {
	margin-right: 15px!important;
}
.margin-right-20 {
	margin-right: 20px!important;
}

.padding-top-0 {
	padding-top: 0px!important;
}
.padding-top-5 {
	padding-top: 5px!important;
}
.padding-top-10 {
	padding-top: 10px!important;
}
.padding-top-15 {
	padding-top: 15px!important;
}
.padding-top-20 {
	padding-top: 20px!important;
}

.padding-left-0 {
	padding-left: 0px!important;
}
.padding-left-5 {
	padding-left: 5px!important;
}
.padding-left-10 {
	padding-left: 10px!important;
}
.padding-left-15 {
	padding-left: 15px!important;
}
.padding-left-20 {
	padding-left: 20px!important;
}

.padding-right-0 {
	padding-right: 0px!important;
}
.padding-right-5 {
	padding-right: 5px!important;
}
.padding-right-10 {
	padding-right: 10px!important;
}
.padding-right-15 {
	padding-right: 15px!important;
}
.padding-right-20 {
	padding-right: 20px!important;
}

.padding-bottom-0 {
	padding-bottom: 0px!important;
}
.padding-bottom-5 {
	padding-bottom: 5px!important;
}
.padding-bottom-10 {
	padding-bottom: 10px!important;
}
.padding-bottom-15 {
	padding-bottom: 15px!important;
}
.padding-bottom-20 {
	padding-bottom: 20px!important;
}

.padding-0 {
	padding: 0px !important;
}
.padding-2 {
	padding: 2px !important;
}
.padding-5 {
	padding: 5px !important;
}
.padding-10 {
	padding: 10px !important;
}
.padding-15 {
	padding: 15px !important;
}
.padding-20 {
	padding: 20px !important;
}
/* class for stuffing space: END */

/* class to tune layout: START */
.middle-aligned {
	margin-top: auto!important;
	margin-bottom: auto!important;
}
/* class to tune layout: END */