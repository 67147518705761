/* BANNER AD STYLING (zone) */

.oio-banner-zone {
	margin: 0px;
	padding: 0px !important;
	background: none !important;
	list-style: none !important;
	list-style-type: none !important;
	list-style-image: none !important;
}

.oio-banner-zone li:before, .oio-banner-zone li:after {
	content: " " !important;
	margin: 0px !important;
	padding: 0px !important;
	display: none !important;
}

.oio-banner-zone li {
	float: left;
	overflow: hidden;
	display: inline !important;
	padding: 0px !important;
	background: none !important;
	clear: none !important;
	border: none !important;
}

.oio-banner-zone li:hover {
	padding: 0px !important;
}

.oio-banner-zone li a {
	background: none !important;
	width: auto !important;
}

.oio-banner-zone a, .oio-banner-zone img {
	border: none;
	display: block;
	margin: 0px !important;
	padding: 0px !important;
	visibility: visible !important;
}

.oio-banner-zone .border, .oio-banner-zone .border:hover {
	border: 2px solid #999 !important;
}

/* TEXT AD STYLING (zone) */

.oio-link-zone {
	margin: 0px;
	padding: 0px !important;
	background: none !important;
	list-style: none !important;
	list-style-type: none !important;
	list-style-image: none !important;
	border: 1px solid #999;
}

.oio-link-zone li:before, .oio-link-zone li:after {
	content: " " !important;
	margin: 0px !important;
	padding: 0px !important;
	display: none !important;
}

.oio-link-zone li {
	float: left;
	overflow: hidden;
	display: inline !important;
	margin: 0px !important;
	padding: 2px !important;
	background: none !important;
	clear: none !important;
	border: none !important;
}

.oio-link-zone li:hover {
	padding: 2px !important;
}

.oio-link-zone li a {
	background: none !important;
	width: auto !important;
}

.oio-link-zone a {
	border: none;
	display: block;
	margin: 0px !important;
	padding: 0px !important;
	visibility: visible !important;
	width: auto !important;
}

.oio-link-zone .cell {
	display: block;
	padding: 5px;
	margin: 0 auto !important;
}

.oio-link-zone .desc {

}

.oio-link-zone .empty {

}

/* TEXT AD STYLING (list) */

.oio-link-list {

}

.oio-link-list li {

}

.oio-link-list a {

}

.oio-link-list .desc {

}

.oio-link-list .empty {

}

/* INLINE AD STYLING */

.oio-inline-left {
	float: left;
	margin-top: 10px;
	margin-right: 10px;
}

.oio-inline-right {
	float: right;
	margin-top: 10px;
	margin-left: 10px;
}

.oio-inline-border, .oio-inline-border:hover {
	border: 2px solid #999;
}

.oio-inline-left a, .oio-inline-left img,
.oio-inline-right a, .oio-inline-right img {
	border: none;
	display: block;
	margin: 0px !important;
	padding: 0px !important;
	visibility: visible !important;
}

/* RSS FEED AD STYLING */

#myrss_feed {
	padding: 10px;
	border: 1px dashed black;
}

.rsscontainer {
	margin-bottom: 1em;
	text-align: left;
}

/* AD BADGE STYLING */

.oio-badge {
	margin-top: 20px;
	margin-bottom: 20px;
	text-align: center;
}

/* AVAILABLE ADS STYLING */

.oio-openslots {

}

/* POSTLINKS STYLING */

.oio-postlinks {
	margin-top: 15px;
}

/* MISC STYLING */

.oio-body {
	margin: 0px !important;
	padding: 0px !important;
}

.oio-center {
	text-align: center;
}

.oio-clear-left {
	clear: left;
}

.oio-clear-right {
	clear: right;
}

.oio-clear {
	clear: both;
}

.oio-clear-left, .oio-clear-right, .oio-clear {
	content: " ";
	height: 0.1px;
	font-size: 0.1px;
	line-height: 0.1px;
	visibility: hidden;
	overflow: hidden;
}

#oio-pixel {
	display: none;
}