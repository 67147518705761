.divclearable {
	display: -moz-inline-stack;
	display: inline-block;
	zoom:1;
	*display:inline;
	vertical-align:middle;
	position: relative;
}

.clearlink {
	position: absolute;
	top: 6px;
	right: 5px;
	cursor: pointer;
	display: -moz-inline-stack;
	display: inline-block;
	zoom:1.2;
	*display:inline;
	height: 16px;
	width: 16px;
	z-index: 2;
	border: 0px solid;
	opacity: 0.3;
}
