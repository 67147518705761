
@media only screen and (max-width : 1499px) {
	html.admin.desktop {
		font-size: 13px;

	}
}
@media only screen and (min-width : 1500px) {
	html.admin.desktop {
		font-size: 14px;
	}
}

.admin {

	.main.segment {
		.main.grid {
			margin: 0 0%;
			.sidebar-column {
				width: 16%;
				padding-left:3px; 
				max-height: 95%;
				overflow-y: auto; 
				overflow-x: visible;

				.ui.search.sidebar-search >.results {
					width: 15em;
				}
			}
			.section-column {
				width: 84%;
			}
		}
	}

	#email_confirmation_alert {
		margin-top: 5px;
	}

	div.smart-search-helpers-button {
		width: 250px;
	}

	table.helper-list {
		.email {
			font-size: 0.85em;
		}
	}


	.ui.page.grid {
		min-width: 320px;
		margin-left: 0%;
		margin-right: 0%;
	}

	@media only screen and (max-width: 991px) {
		.ui.page.grid {
			padding: 0% 2%;
		}
	}
	@media only screen and (min-width: 992px) {
		.ui.page.grid {
			padding: 0% 4%;
		}
	}
	@media only screen and (min-width: 1500px) {
		.ui.page.grid {
			padding: 0% 6%;
		}
	}
	@media only screen and (min-width: 1750px) {
		.ui.page.grid {
			padding: 0% 9%;
		}
	}
	@media only screen and (min-width: 2000px) {
		.ui.page.grid {
			padding: 0% 12%;
		}
	}

	#account_list .inline.field input {
		width: inherit;
	}

	.user-change-log-text {
		word-wrap: break-word;
	}

}