
html.desktop {
	font-size: 16px;
}

.desktop {

	body {
		background-color: @doulaeasy_bg_red_deeper;
	}

	.ui.container.main.page.board {
		width: 1200px;
	}

	div.inner.main.page {
		margin: 1em;
		// margin-top: 0.5em;
	}

	.top.banner.grid {
		.sub.large.text {
			margin-top: 1.8em;
		}
	}

	div.nav-menu.ui.menu {
		background-color: white;
	}

	// .doulaeasy-logo {
	// 	margin-left: 1em;
	// }
	
	#header {
		height: 62px;
		#doulaeasy-desktop-menu {
			box-shadow: 1px 2px 10px 2px rgba(100, 100, 100, 0.3);	
		}
	}

	.toast-top-full-width {
		top: 72px;
		right: 0;
		width: 100%;
	}

	#right_header {
		.small.button {
			font-size: .85714286rem !important;
		}
	}

	/* solved facebook like comment box overflow cut off problem */
	.fb-like span{display:block;}
	.fb_iframe_widget_lift {z-index:10}
	// .fb-like span{overflow:inherit !important; width:480px !important; margin-right:-350px;}


	// @media only screen and (min-width: 1500px) {
	// 	.ui.modal {
	// 		width: 67%;
	// 		margin-left: -33.5%;
	// 	}
	// }

	// @media only screen and (max-width: 1499px) {
	// 	.ui.modal {
	// 		width: 75%;
	// 		margin-left: -37.5%;
	// 	}
	// }

	// @media only screen and (max-width: 1399px) {
	// 	.ui.modal {
	// 		width: 79%;
	// 		margin-left: -39.5%;
	// 	}
	// }

	// @media only screen and (max-width: 1299px) {
	// 	.ui.modal {
	// 		width: 83%;
	// 		margin-left: -41.5%;
	// 	}
	// }
 	
 	.top.banner.row {
 		margin-top: -10px;
 	}

	.top.banner .title {
		margin-top: 13px;
		margin-left: 20px;
		margin-bottom: 5px;
	}

	.content.page {
		// margin-left:50px; 
		// margin-right:50px;
	}

	.content.padding {
		padding-left:50px;
		padding-right:50px;
	}

	.self.introduction.text {
		margin-left:30px;
		margin-right:30px;
	}

	.ui.signin.segment .ui.page.grid {
		padding: 0 15%;
	}

	.ui.register.form .ui.page.grid {
		padding: 0 5%;
	}
	.ui.register.requirement.form .ui.page.grid {
		padding: 0 20%;
	}
	.ui.supplement.form .ui.page.grid {
		padding: 0 15%;
	}
	.ui.signin.segment .ui.login.page.grid {
		padding: 0 20%;
	}

	#register_modal {
		#helper_register_button {
			margin-left: 3em;
		}
	}


	.hiring-post {
		.ui.segment.hiring-board {
			.ui.cards {
				.hiring-card.card {
					>.content:not(.extra) {
						position: relative;
						padding-bottom: 12em;
					}
					>.content.interested-helpers-non-viewable:not(.extra) {
						padding-bottom: 4em;
					}
				}
			}
		}

		.ui.cards {
			.hiring-card.card {
				
				div.interested-helpers-div {
					bottom:1em; 
					left:1em; 
					right:1em; 
					position: absolute;
				}
			}
			.hiring-card.card.editable {
				cursor: pointer;
			}
		}
	}


	div.more-info-fields.ui.grid, .hiring-request.ui.form {

		>.row:first-child {
		  padding-top: 1rem;
		}

		>.row {
		  padding: 0.5rem 0;
		}

		.other-region.ui.dropdown {
			width: initial;
			padding: 10px;
			margin-left: 15px;
		}
		.hong-kong-district.ui.select {
			width: initial;
			// display: inline;
			// padding: 6px 10px;
			margin-left: 15px;
		}
		.provinces.ui.select {
			width: initial;
			// display: inline;
			// padding: 6px 10px;
			margin-left: 15px;
		}
		.source-others.ui.input {
			width: 12em;
			// display: inline;
			// padding: 6px 10px;
			margin-left: 15px;
		}

	}
	
	.parent.register.ui.form {
	}

	.three-in-a-row-images {
		margin-left: 1em;
	}

	.edit.page.content {
		padding: 0 15%;
	}

	.helper_card_email_text {
		color:black;
	}
	.helper_card_phone_text {
		color:black;
	}

	a.tel-phone  {
		// color: rgba(0, 0, 0, 0.8);
		text-decoration: inherit;
	}

	.ui.breadcrumb {
		margin: 0em 0;
		display: inline-block;
		vertical-align: middle;
	}

	.search.bar.outer.boundary {
		// max-height:113px; 
		// z-index:100; 
		overflow: visible;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	#search_bar {
		.left-most-column {
			padding-right: 0px;
		  	padding-left: 25px;
		}
		.title-column {
			width: auto!important;
		}
		.select-role-column {
			margin-top: 1.2em;
			// margin-left: 1em;
			width: 100%!important;
		}
		.more-search-filters {
		  	padding-left: 10px;
		  	#other_languages {
		  		padding: 0.2em 0.1em;
		  	}
		}

	}

	div.search.result {
		#boxes {
			margin-top: 1em;
			// padding-left: 1em;
			// padding-right: 1em;
			div.helper-card {
				>.content {
					padding: 0;
					.ui.cards {
						margin: 0;
					}
				}
			}
		}
		#search_result_label {
			margin-top:0.5em;
		}
	}

	.about.author.segment .row.content {
		margin-top: 10px;
	}

	.home.content.ui.grid>.column {
		margin-top: 0rem;
	}


	.sidebar-column {
		padding-left: 15px!important;
		padding-right: 15px!important;
	}

	.home.content {

		div.top-section.row {
			div.left-column.column {
				// padding-right: 4px;
				padding-left: 2em;
			}
			div.right-column.column {
				// padding-left: 4px;
				padding-right: 2em;
			}
		}

		.h1-ad {
			// height: 90px;
			width: 100%!important;
			padding-top: 0em!important;
			padding-bottom: 0em!important;
		}
		.h2-ad {
			padding-top: 0em!important;
			padding-bottom: 0em!important;
		}
		.h3-ad {
			padding-top: 0em!important;
			padding-bottom: 0em!important;
		}
	}

	#more_search_filters_button {
		display: none;
	}

	.helper-profile {
		.hiring_info_explained.ui.table {
			margin-top: 15px;
		}
		.ui.page.grid {
			padding: 0 50px;
		}

		#e1_ad_item {
			height: 90px;
		}
		#e2_ad_item {
			height: 90px;
		}
	}

	// .ui.modal > .close {
	// 	top: -1.3em;
	// }
	
	#toggle_desktop_button {
		margin-top: 10px;
	}

	#district_selector {
		.hong-kong-region-figure-column {
			padding-left: 40px;
		}
	}

}