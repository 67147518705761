
/* awesome font */
i.fa {
	opacity: 0.75;
	margin-right: .6em;
	line-height: 1;
	-webkit-transition: opacity .1s ease;
	-moz-transition: opacity .1s ease;
	transition: opacity .1s ease;
}
i.fa:hover {
  opacity: 0.9;
}
.ui.button:hover > i.fa {
  opacity: 0.9;
}